<template>
	<b-card>
		<ValidationObserver>
			<form @submit.prevent="saveItem">
				<b-row>
					<b-col cols="12" md="6">
						<h3 class="mb-3">Rekomendasi Service</h3>
						<div class="form-group">
							<label>Nama</label>
							<validation-provider
								name="description"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text" 
									class="form-control" 
									v-model="formRekomendasi.name"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Nama Rekomendasi"
								>
								<small
									v-for="(validation, index) in validations.name"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="text-right">
							<b-button
								variant="primary"
								class="btn waves-effect waves-float waves-light btn-primary mt-1"
								@click="addFeatures"
							>
								Add
							</b-button>
						</div>
						<div
							v-for="(feature, index) in inputFeature"
							:key="index"
						>
							<div class="d-flex justify-content-between align-items-center mb-1">
								<b-button
									variant="danger"
									class="btn waves-effect waves-float waves-light btn-danger"
									@click="removeFeatures(index)" v-show="index > 0"
								>
									Delete Features
								</b-button>
							</div>
							<div class="form-group">
								<label>Fitur</label>
								<v-select
									v-model="serviceSelected[index]"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									label="name"
									placeholder="-- Pilih Fitur --"
									:options="services"
									@input="changeService($event, index)"
								/>
								<small
									v-for="(validation, key) in validations['features.' + index + '.uuid']"
									:key="`errorName${key}`"
									class="text-danger"
								>{{ validation }}</small>
							</div>
							<div class="form-group">
								<label>Benefit Count</label>
								<b-form-spinbutton
									id="demo-sb"
									v-model="feature.benefit_count"
									:min="serviceSelected[index].qty"
									max="1000"
									:step="serviceSelected[index].qty"
									:value="feature.qty * serviceSelected[index].qty"
									@change="benefitMultiple($event, index)"
								/>
								<small
									v-for="(validation, key) in validations['features.' + index + '.benefit_count']"
									:key="`errorName${key}`"
									class="text-danger"
								>{{ validation }}</small>
							</div>
							<!-- <input type="hidden" v-model="feature.qty"> -->
						</div>
					</b-col>

					<b-col cols="12" md="6">
						<h3 class="mb-3">Segmentasi</h3>
						<div class="form-group" v-for="(items, key) in segments" :key="key">
							<input type="hidden" :value="items.key" :ref="`val${items.key}`">

							<label>{{ items.label }}</label>
							<validation-provider
								name="segments"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="email" 
									class="form-control" 
									:ref="items.key"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									v-if="items.key == 'email'"
									v-model="inputSegment[key]"
								>
								<v-select
									label="name"
									v-model="inputSegment[key]"
									:options="optionSearch"
									v-if="vSelectTagKeys.includes(items.key)"
									placeholder="-- Pilih --"
									:ref="items.key"
									@search="onSearch"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:reduce="option => option.name"
								/>
								<select 
									class="form-control" 
									v-model="inputSegment[items.key]"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									v-if="regularSelectTagKeys.includes(items.key)"
									:ref="items.key"
								>
									<option value="">Any</option>
									<option :value="select.value" v-for="(select , index) in items.available_answer" :key="index">
										{{ select.label }}
									</option>
								</select>
								<small
									v-for="(validation, key) in validations['segmentations.' + key + '.key']"
									:key="`errorName${key}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group">
							<label>Partnership</label>
							<validation-provider
								name="partners"
								rules="required"
								v-slot="{ errors }"
							>
								<v-select
									v-model="partnerSelected"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:state="errors.length > 0 ? false:null"
									label="name"
									multiple
									placeholder="-- Pilih Partner --"
									:options="partners"
								/>
								<small
									v-for="(validation, index) in validations.partners"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
					</b-col>
				</b-row>
				
				<b-col cols="12" class="p-0 mt-2">
					<template v-if="isLoading">
						<button
							variant="primary"
							class="btn waves-effect waves-float waves-light btn-primary"
							disabled
						>
							<b-spinner class="align-middle"></b-spinner>
							<strong>Loading...</strong>
						</button>
					</template>
					<template v-else>
						<button
							variant="primary"
							class="btn waves-effect waves-float waves-light btn-primary"
						>
							Save
						</button>
						&nbsp;
						&nbsp;
						<a
							@click="handleBackButton()"
							variant="secondary"
							class="btn waves-effect waves-float waves-light btn-secondary"
						>
							Cancel
						</a>
					</template>
				</b-col>
			</form>
		</ValidationObserver>
	</b-card>
</template>

<script>
import {
	BCard, 
	BFormGroup, 
	BFormRadio, 
	BFormFile,
	BRow, 
	BCol,
	BButton,
	BSpinner,
	BFormCheckbox,
	BFormSpinbutton
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import _ from 'lodash'
import { successNotification, errorNotification } from '@/auth/utils'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BRow,
		BCol,
		BCard,
		BButton,
		vSelect,
		BSpinner,	
		BFormFile,
		BFormGroup,
		BFormRadio,
		BFormCheckbox,
		BFormSpinbutton,
		ValidationProvider,
		ValidationObserver,
	},

	setup() {
		return {
			successNotification,
			errorNotification,
		}
	},

	data() {
		return {
			required,
			isLoading: false,
			formRekomendasi:{
				name: '',
				features:[],
				segmentations: [],
			},
			inputFeature: [
				{
					uuid: '',
					benefit_count: '',
					qty: '',
				},
			],
			services:[],
			segments: [],
			serviceSelected: [],
			inputSegment: [],
			optionSearch :[],
			validations: '',
			selected: '',
			image_url: null,
			partners: [],
			partnerSelected:[],
			vSelectTagKeys: [
				'sub_profesi_pengusaha',
				'sub_profesi_profesi',
				'sub_profesi_freelance'
			],
			regularSelectTagKeys: [
				'profesi',
				'sub_profesi_pegawai',
				'punya_npwp',
				'punya_efin',
				'punya_djp',
				'harta',
				'status_menikah',
				'profesi_istri',
				'surat_pisah_harta',
				'punya_anak',
				'profesi_anak'
			]
		}
	},

	mounted() {
		this.loadPartner();
		this.loadService();
		this.loadSegment();
	},

	methods: {
		handleBackButton() {
			history.back()
		},
		onSearch(search, loading) {
			if (search.length) {
				loading(true);
				this.multipleSelect(loading, search, this);
			} 
			else {
				this.loadSubprofession()
			}
		},

		async loadPartner() {
			this.$http.get('admin/partners')
			.then(response => {
				response.data.data.forEach(element => {
					if (element.name == 'Hipajak') {
						this.partnerSelected.push(element) 
					}
					this.partners.push({'uuid':element.uuid, 'name':element.name})
				});
			})
		},

		loadSubprofession() {
			this.$http.get('admin/fill-questions/compliting-profile/sources/business-types?keyword'
			).then(response => {
				this.optionSearch = response.data.data
			})
		},

		multipleSelect: _.debounce((loading, search, vm) => {
			vm.$http.get('admin/fill-questions/compliting-profile/sources/business-types?keyword='+ search
			).then(response => {
				vm.optionSearch = response.data.data
				loading(false)
			}).catch(() => {
				loading(false)
			})
		}, 300),

		async loadService() {
			this.$http.get('admin/services?type=all')
			.then(response => {
				const permissionData = response.data.data
				permissionData.forEach(element => {
					this.services.push({
						'uuid': element.uuid,
						'name': element.name,
						'qty': element.qty,
					})
				});
			})
		},

		loadBundling() {
			this.inputFeature.forEach((value) => {
				this.serviceSelected.push({
					'uuid': value.uuid,
					'name': value.name,
					'qty': value.benefit_count / value.qty,
				})
			})
		},

		async loadSegment() {
			await this.$http.get('admin/segmentations')
			.then(response => {
				this.segments = response.data.data
				for (const object of response.data.data) {
					this.inputSegment[object.key] = ''
				}
			})
			for (let index = 0; index < this.segments.length; index++) {
				const element = this.segments[index];
				this.$refs[element.key][0].value = this.formRekomendasi.segmentations[index]?.value || ''
				console.log(this.$refs[element.key][0].value, 'ini data');
			}
		},

		changeService(service, index) {
			this.inputFeature[index].qty = 1
			this.inputFeature[index].benefit_count = 1 * service.qty
			console.log(service)
			console.log(this.serviceSelected)
		},

		benefitMultiple(event, index) {
			this.inputFeature[index].qty = event / this.serviceSelected[index].qty
		},

		cancelPhoto() {
			this.image_url = '',
			this.formRekomendasi.image = ''
		},
		changePhoto(event) {
			this.formRekomendasi.image = event.target.files[0]
			this.showPhoto(event.target.files[0])
		},
		showPhoto(file) {
			const reader = new FileReader()
			reader.onload = e => {
				this.image_url = e.target.result
			}
			reader.readAsDataURL(file)
		},

		addFeatures() {
        	this.inputFeature.push({ 
				uuid: '',
				benefit_count: '',
				qty: '',
			});
			this.serviceSelected.push({ 
				uuid: '',
				name: '',
				qty: '',
			});
		},

		removeFeatures(index) {
			this.inputFeature.splice(index, 1);
		},

		saveItem() {
			this.isLoading = true
			this.formRekomendasi.features = this.inputFeature
			const payload = new FormData()
			payload.append("name", this.formRekomendasi.name)
			if (this.formRekomendasi.features !== null) {
				this.formRekomendasi.features.forEach((features, index) => {
					Object.keys(features).forEach(key => {
						if(key != 'description' || key != 'slug' || key != 'group') {
							if (key == 'uuid') {
								payload.append('features' + '['+ index + '][' + key + ']', this.serviceSelected[index].uuid);
							} else if (key == 'qty') {
								payload.append('features' + '['+ index + '][' + key + ']', features[key]);
							} else if (key == 'benefit_count') {
								payload.append('features' + '['+ index + '][' + key + ']', features[key]);
							}
						}
					});
				});
			}

			for (let index = 0; index < this.partnerSelected.length; index++) {
				const partnerUuid = this.partnerSelected[index]['uuid'];
				payload.append('partners[]', partnerUuid)
			}

			for (let index = 0; index < this.segments.length; index++) {
				const element = this.segments[index];
				payload.append("segmentations[" + index + "][key]", this.$refs[`val${element.key}`][0].value)
				payload.append("segmentations[" + index + "][value]", this.$refs[element.key][0].value || '')
				console.log(this.$refs[`val${element.key}`], 'ini refs');
				
			}
			this.$http.post(`admin/features/recomendations`, payload , {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(response => {
				successNotification(this, 'Success', 'Rekomendasi Service Berhasil diedit!')
				this.$router.push({ name: 'rekomendasi-service' })
			}).catch(error => {
				if (error.response.data.meta.messages) {
					errorNotification(this, 'Oops!', error.response.data.meta.messages) 
					this.isLoading = false
				}
				if (error.response.data.meta.validations.name || error.response.data.meta.validations.status || error.response.data.meta.validations.price ) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Nama', this.validations.name.join(' '))
				} else {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Opps!', 'Terjadi kesalahan, Periksa kembali data anda.')
				}
				this.isLoading = false
			})
		},
	},

	async created() {
		await this.loadBundling()
		await this.loadSubprofession()
	}

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
	.form-check-inline {
		flex-wrap: wrap;
	}
	.images__hint {
		font-size: 10px;
		margin-bottom: 0;
	}
</style>